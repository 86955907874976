// src/components/molecules/phone/phone-config/phone-config/phone-config.tsx

import {
  AdjustmentsHorizontalIcon,
  MicrophoneIcon,
  SpeakerWaveIcon,
} from '@heroicons/react/24/solid';
import AudioDeviceSelect from 'components/atoms/audio-device-select';
import CallQualityRadio from 'components/atoms/call-quality-radio';
import { useAvailableAudioDevices } from 'hooks/phones/providers/twilio/audio-devices';
import { PhoneConfigActions } from 'hooks/phones/types';

interface PhoneConfigProps {
  phoneConfigActions: PhoneConfigActions;
}

const PhoneConfig: React.FC<PhoneConfigProps> = ({ phoneConfigActions }) => {
  const { inputDevices, outputDevices } = useAvailableAudioDevices();

  const {
    selectedInputDevice,
    setSelectedInputDevice,
    selectedOutputDevice,
    setSelectedOutputDevice,
    selectedDeviceBitrate,
    setDeviceBitrate,
  } = phoneConfigActions;

  return (
    <div className="space-y-4 mx-4">
      <div className="flex space-x-2">
        <MicrophoneIcon className="h-5 w-5 text-gray-500" />
        <AudioDeviceSelect
          // TODO: check when implementing i18n
          label="Micrófono"
          devices={inputDevices}
          selectedDeviceId={selectedInputDevice}
          onChange={setSelectedInputDevice}
        />
      </div>
      <div className="flex space-x-2">
        <SpeakerWaveIcon className="h-5 w-5 text-gray-500" />
        <AudioDeviceSelect
          // TODO: check when implementing i18n
          label="Altavoz"
          devices={outputDevices}
          selectedDeviceId={selectedOutputDevice}
          onChange={setSelectedOutputDevice}
        />
      </div>
      <div className="flex space-x-2">
        <AdjustmentsHorizontalIcon className="h-5 w-5 text-gray-500" />
        <CallQualityRadio
          selectedDeviceBitrate={selectedDeviceBitrate}
          setSelectedDeviceBitrate={setDeviceBitrate}
        />
      </div>
    </div>
  );
};

export default PhoneConfig;
