// src/components/atoms/modals/modal.tsx

import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton,
  Typography,
} from '@material-tailwind/react';
import { DocumentDuplicateIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useAlert } from 'src/contexts/alert-context';

interface ModalProps {
  children: JSX.Element;
  copyableContent?: string;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  headerClasses?: string;
  bodyClasses?: string;
}

const Modal: React.FC<ModalProps> = ({
  children,
  copyableContent,
  isOpen,
  onClose,
  title,
  headerClasses = '',
  bodyClasses = '',
}) => {
  const { showAlert } = useAlert();

  const copyToClipboard = async () => {
    if (!copyableContent) return;

    try {
      await navigator.clipboard.writeText(copyableContent);
      // TODO: check when implementing i18n
      showAlert('Resumen copiado al portapapeles.', 'success');
    } catch (err) {
      // TODO: check when implementing i18n
      showAlert('Error al copiar el resumen.', 'error');
    }
  };

  return (
    <Dialog
      open={isOpen}
      handler={onClose}
      className="relative m-4 max-w-full max-h-full overflow-auto md:max-h-[80%]"
    >
      <DialogHeader className="flex py-2 md:py-4 justify-between">
        <div className="flex">
          {title ? (
            <Typography
              variant="h4"
              className={`leading-relaxed ${headerClasses}`}
            >
              {title}
            </Typography>
          ) : (
            <span />
          )}
          {copyableContent && (
            <DialogFooter className="p-0">
              <IconButton
                onClick={copyToClipboard}
                variant="text"
                className="mx-1"
              >
                <DocumentDuplicateIcon strokeWidth={2} className="h-6 w-6" />
              </IconButton>
            </DialogFooter>
          )}
        </div>
        <Button variant="text" onClick={onClose}>
          <XMarkIcon strokeWidth={2} className={'h-6 w-6'} />
        </Button>
      </DialogHeader>
      <DialogBody className={`overflow-auto p-0 m-0 md:mb-2 ${bodyClasses}`}>
        {children}
      </DialogBody>
    </Dialog>
  );
};

export default Modal;
