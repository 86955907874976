import { usePhoneContext } from 'src/contexts/phone-context';
import {
  CallProvider,
  PhoneCallActions,
  PhoneConfigActions,
  PhonePadActions,
  StartCallParams,
} from '../../types';
import { useStartBridgeCall } from 'hooks/api/calls/start-calls';
import { NewBridgeCall } from 'src/api/generated';
import STATES from 'states/index';
import { useEffect } from 'react';
import { useAlert } from 'src/contexts/alert-context';
import { standardizePhoneNumber } from 'src/utils/formatting/phone';

export interface PhoneCallActionsProps {
  startBridgeCall: (newCallBridgeCall: NewBridgeCall) => void;
  standardizedPhoneNumber: string | null;
  setPhoneNumber: (number: string) => void;
  setPhoneState: (number: string) => void;
}

export interface PhonePadActionsProps {
  formattedPhoneNumber: string | null;
  setPhoneNumber: (number: string) => void;
}

const usePhoneCallActions = ({
  startBridgeCall,
  standardizedPhoneNumber,
  setPhoneNumber,
}: PhoneCallActionsProps): PhoneCallActions => {
  const startCall = async ({
    phoneNumber,
    callProviderData,
    callCustomData,
  }: StartCallParams = {}) => {
    let toNumber = standardizedPhoneNumber;
    if (phoneNumber) {
      toNumber = standardizePhoneNumber(phoneNumber);
      setPhoneNumber(phoneNumber);
    }
    if (!toNumber) {
      console.info(`Invalid phone number: ${toNumber}`);
      return;
    }

    startBridgeCall({
      to: toNumber,
      callProviderData,
      callCustomData,
    });
  };

  // Unsupported actions for this provider
  const acceptCall = () => {};
  const rejectCall = () => {};
  const endCall = () => {};
  const muteCall = (_isMuted: boolean) => {};

  return { startCall, acceptCall, rejectCall, endCall, muteCall };
};

const usePhonePadActions = ({
  formattedPhoneNumber,
  setPhoneNumber,
}: PhonePadActionsProps): PhonePadActions => {
  const onPadDigitClick = (digit: string) => {
    if (!formattedPhoneNumber) return;

    setPhoneNumber(formattedPhoneNumber + digit);
  };

  const onPadDeleteClick = () => {
    if (!formattedPhoneNumber) return;

    setPhoneNumber(formattedPhoneNumber.slice(0, -1));
  };

  // Unsupported action for this provider
  const onCallPadDigitClick = (_digit: string) => {};

  return { onPadDigitClick, onPadDeleteClick, onCallPadDigitClick };
};

export const useBridgeCallProvider = (): CallProvider => {
  const { showAlert, showModalAlert } = useAlert();

  const onSuccessCallStarted = () => {
    const message = `
    Acepta la llamada en tu teléfono para llamar automáticamente al contacto.
    Llamando al número ${formattedPhoneNumber}.
    `;
    const title = 'Ahora recibirás una llamada';
    const durationInMs = 10000;
    showModalAlert(message, title, undefined, durationInMs);
  };

  const isMissingIndividualPhoneNumberError = (error: Error | null) => {
    if (!error) return false;
    return error.name === 'ApiError' && error.stack?.includes('status: 422');
  };
  const onFailCallStarted = (error?: Error | null) => {
    if (!error) return;
    if (isMissingIndividualPhoneNumberError(error)) {
      showAlert(
        `No se puede completar la llamada por teléfono. Por favor, configure ` +
          `su teléfono personal para redirigir la llamada y vuelva a intentarlo.`,
        'error'
      );
    }
  };

  const {
    standardizedPhoneNumber,
    formattedPhoneNumber,
    setPhoneState,
    setPhoneNumber,
  } = usePhoneContext();
  const { loading, error, startBridgeCall } = useStartBridgeCall(
    onSuccessCallStarted,
    onFailCallStarted
  );

  const handledError = isMissingIndividualPhoneNumberError(error)
    ? null
    : error;

  // Using external phone for bridge provider
  const phoneConfigActions: PhoneConfigActions = {
    // TODO: check when implementing i18n
    selectedInputDevice: 'Teléfono',
    setSelectedInputDevice: (_deviceId: string) => {},
    selectedOutputDevice: 'Teléfono',
    setSelectedOutputDevice: (_deviceId: string) => {},
    selectedDeviceBitrate: 0,
    setDeviceBitrate: (_bitrate: number) => {},
  };

  const callActions = usePhoneCallActions({
    startBridgeCall,
    standardizedPhoneNumber,
    setPhoneNumber,
    setPhoneState,
  });
  const padActions = usePhonePadActions({
    formattedPhoneNumber,
    setPhoneNumber,
  });

  useEffect(() => {
    setPhoneState(STATES.PHONE.READY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    callActions,
    padActions,
    phoneConfigActions,
    loading,
    error: handledError,
    warnings: [],
    name: 'bridge',
    callerNumber: undefined,
    onCallNumber: formattedPhoneNumber,
  };
};
